<template>
	<dialogModule
		width="1000px"
		top="13"
		title="设置中心"
		v-if="settingVisible"
		:dialogVisible="settingVisible"
		:close-on-press-escape="false"
		@close="close">
		<span slot="close" class="el-icon-close close-span" @click="close"></span>
		<div class="content" slot="content">
			<div class="row">
				<div class="tabs">
					<template v-for="(item, index) in tabsList">
						<div
							class="row align-center tabs-list"
							:class="[curTabs === item.id ? 'check-color' : '']"
							:key="index"
							@click="curTabschange(item)">
							<span :class="[item.id === '0' ? 'iconfont' : '', item.iconClass]"></span>
							<span class="tabs-label">{{ item.tabsTitle }}</span>
						</div>
					</template>
				</div>
				<div class="right-box" v-show="curTabs === '0'">
					<div class="row space-between align-center box-row">
						<div>基座版本</div>
						<div>
							<el-tag effect="dark" size="small" type="primary">{{ curVersion }}</el-tag>
						</div>
					</div>
					<div class="row space-between align-center box-row">
						<div>应用版本</div>
						<el-tag effect="dark" size="small" type="primary">{{ appVersion }}</el-tag>
					</div>
					<div class="again-check check-btn cursor" @click="checkUpdate">检查更新</div>
				</div>
				<div class="right-box" v-show="curTabs === '1'">
					<div class="row space-between align-center box-row">
						<div>连接状态</div>
						<div @click="connectWS" class="network cursor"
							 :style="{ 'color': wsConnected ? '#43d268' : 'red' }">
							{{ wsConnected ? "正常" : "未连接" }}
							<span class="again-check" v-if="!wsConnected">重新连接</span>
						</div>
					</div>
					<div class="row space-between align-center box-row">
						<div>麦克风设备</div>
						<div>
							<div v-for="(item,idx) in microphoneList" :key="idx">
								{{ idx + 1 }}.{{ item['deviceName'] }}
							</div>
						</div>
					</div>
				</div>
        <div class="right-box" v-show="curTabs === '2'">
          <div style="margin-top: 30px;">
            <el-button type="primary" @click="openFolder('logs')">打开日志所在目录</el-button>
          </div>
        </div>
			</div>
			<UpdateModule v-if="isUpdate" :versionData="versionData" @close="isUpdate = false"></UpdateModule>
		</div>
	</dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import UpdateModule from "./UpdateModule.vue";
import {appUpdate} from "@/libs/http/api.js";
import eventBus from '@/bus/index.js';
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";
import request from '@/libs/http/index'
const urlConfig = window.urlConfig;
export default {
	name: "setting",
	components: {
		dialogModule,
		UpdateModule
	},
	data() {
		return {
			settingVisible: true,
			tabsList: [
				{iconClass: "icon-tishi1", tabsTitle: "版本信息", id: "0"},
				{iconClass: "el-icon-phone", tabsTitle: "语音通话", id: "1"},
				{iconClass: "el-icon-wallet", tabsTitle: "日志反馈", id: "2"},
			],
			curTabs: "0",
			curVersion: '1.0.0',
			appVersion: '',
			microphoneList: [],
			isUpdate: false,
			versionData: null,
      logPathDir: ''
		};
	},
	created() {
		this.fetchAppVersion();
		this.microphoneList = this.$store.state.deviceMicrophoneList;
	},
	mounted() {
		ipc.invoke(ipcApiRoute.getVersion).then(r => {
			this.curVersion = r
		})
	},
	computed: {
		wsConnected() {
			return this.$store.state.wsConnected;
		}
	},
	methods: {
		close() {
			this.settingVisible = false;
			this.$emit("close");
		},
		curTabschange(item) {
			if (this.curTabs === item.id) return
			this.curTabs = item.id
		},
		fetchAppVersion(){
			request.get(
				urlConfig.prodPosWebRoot + '/version.json?' + new Date().getTime(),
				success => {
					this.appVersion = success
				},
				error => {
				
				}
			)
		},
		checkUpdate() {
			let visCode = Number(this.curVersion.replaceAll(".", ""));
			appUpdate({
				current: visCode,
				platform: "client",
				userId: this.$cache.local.get('userId')
			}).then(data => {
				this.versionData = data;
				if (Number(data["versionCode"]) > visCode) {
					this.isUpdate = true;
					this.versionData.curVersion = this.curVersion;
				} else {
					this.$message({showClose: true, message: "暂无更新", type: "warning", duration: 2000});
				}
			}).catch((err) => {
				this.$message({showClose: true, message: err, type: "error", duration: 2000});
			});
		},
		connectWS() {
			if (!this.wsConnected) {
				eventBus.$emit('globalInitWebSocket');
			}
		},
    openFolder(type){
      ipc.invoke(ipcApiRoute.doSocketRequest, {id: type}).then(r=>{})
    }
	},
};
</script>

<style lang="scss" scoped>
.close-span {
	color: #999;
	padding: 10px;
	margin-right: -10px;
	cursor: pointer;
}
.content {
	padding: 15px;
}

.tabs {
	height: 672px;
	background: #ffffff;
	box-shadow: 0 0 8px 0 rgba(204, 204, 204, 0.39);
	border-radius: 4px;
	margin-right: 20px;
	padding: 10px 20px 0;
	.tabs-list {
		width: 167px;
		height: 45px;
		background: #ffffff;
		border-radius: 4px;
		padding-left: 22px;
		color: #333333;
		cursor: pointer;
		.tabs-label {
			font-size: 16px;
			margin-left: 9px;
		}
	}
	.check-color {
		background: #067cf2;
		color: #fff;
	}
}
.right-box {
	flex: 1;
	.box-row {
		font-size: 16px;
		background: #fff;
		border-radius: 4px;
		padding: 0 10px;
		height: 55px;
		margin-bottom: 20px;
		box-shadow: 0 0 8px 0 rgba(204, 204, 204, 0.39);
		.again-check {
			color: #666;
			font-size: smaller;
			margin-left: 8px;
		}
	}
	.check-btn{
		margin: 100px auto 0;
		width: 120px;
		line-height: 40px;
		border-radius: 4px;
		background: #0b83f3;
		text-align: center;
		font-size: 18px;
		color: #fff;
	}
}
</style>